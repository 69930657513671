import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'longNumber',
    standalone: true
})
export class LongNumberPipe implements PipeTransform {

    private readonly SUFFIXES: string[] = ["", "K", "M", "B"];

    transform(value: number = 0, maxDecimalPlaces: number = 2): string {
        let suffixIndex: number = 0,
            result: number = value,
            factor: number = 1000;

        while (1) {
            if ((value /= factor) >= 1) {
                suffixIndex++;
                result = value;
            } else {
                break;
            }
        }

        if (suffixIndex > 0) {
            const formattedResult = this._format(result, maxDecimalPlaces);
            return formattedResult + this.SUFFIXES[suffixIndex];
        } else {
            return result.toString();
        }
    }

    
    private _format = (value: number, maxDecimalPlaces: number): string => {
        
        // Checks the value has a decimal part, if not returns the value as is
        if (value % 1 === 0) {
            return value.toString();
        } else {
            return value.toFixed(maxDecimalPlaces);
        }
    }
}